import React from "react";
import { Helmet } from "react-helmet";

const WebsiteTitle = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title?.length ? "Mechanical C & T | " + title : "Mechanical C & T"}
      </title>
    </Helmet>
  );
};

export default WebsiteTitle;
