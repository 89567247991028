import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import persistStore from "redux-persist/es/persistStore";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18next from "@localization/i18next";
import "@assets/styles/Styles.scss";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const direction = "ltr";
const persistor = persistStore(store);
ConfigProvider.config({
  theme: {
    primaryColor: "#A7C0E8",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18next}>
        <ConfigProvider direction={direction}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
