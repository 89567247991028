import React, { useState } from "react";
import DetectWindowSize from "../../helpers/DetectWindowSize";

function LandingPage() {
  const [breakpointData, setBreakpointData] = useState(null);
  return (
    <section className="mct-section ">
      <DetectWindowSize getBreakPointData={(data) => setBreakpointData(data)} />
      <div className="landing-page">
        <div className="landing-title">
          Delivering Successful Energy Projects
        </div>
      </div>
    </section>
  );
}

export default LandingPage;
