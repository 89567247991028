import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ar } from "./ar";
import { en } from "./en";

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: en },
		ar: { translation: ar },
	},
	interpolation: { escapeValue: false },
	react: {
		useSuspense: true,
	},
});

export default i18n;
