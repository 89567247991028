import WebsiteTitle from "./helpers/WebsiteTitle";
import RouterFile from "./router";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18next from "@localization/i18next";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { Helmet } from "react-helmet";
import Global from "./modules/Global/Global";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const title = location.pathname.split("/")[1].toUpperCase();
  const { local, dir } = useSelector((state) => state.local);
  const { authorization } = useSelector((state) => state.auth);

  return (
    <div className="App">
      <Helmet>
        <html lang={local} dir={dir}></html>
      </Helmet>
      <ToastContainer />
      <WebsiteTitle title={title} />
      <Global />{" "}
    </div>
  );
}

export default App;
