import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Row, Col, Button } from "antd";
import whatsAppIcon from "../../assets/icons/whatsAppicon.svg";
import phoneIcon from "../../assets/icons/phoneIcon.svg";
import mailIcon from "../../assets/icons/messageIcon.svg";
import { contactUs } from "../../network";
import { toastError, toastSuccess } from "../../helpers/toasters";

function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const handleOnChangeName = (e) => {
    setName(e.target.value);
    setNameError(false);
  };
  // const handleOnChangePhone = (e) => {
  //   if (e.target.value.test(/^\+?\d{1,3}-?\d{3}-?\d{4}$/)) {
  //     setPhone(e.target.value);
  //     setPhoneError(false);
  //   }
  // };
  const handleOnChangePhone = (e) => {
    const phoneRegex = /^[\d-+]*$/;
    if (phoneRegex.test(e.target.value)) {
      setPhone(e.target.value);
      setPhoneError(false);
    }
  };
  const handleOnChangeMessage = (e) => {
    setMessage(e.target.value);
    setMessageError(false);
  };

  const onFinish = (e) => {
    e.preventDefault();
    let hasError = [];
    if (!name) {
      setNameError(true);

      hasError.push("1");
    }
    if (!phone) {
      setPhoneError(true);
      hasError.push("2");
    }
    if (!message) {
      setMessageError(true);
      hasError.push("3");
    }
    if (hasError?.length === 0) {
      setLoading(true);
      //call api
      const data = {
        name,
        phone,
        message,
      };
      contactUs(
        data,
        (success) => {
          if (success?.isSuccess) {
            setLoading(false);
            toastSuccess(success.data?.message);
            setName("");
            setPhone("");
            setMessage("");
          } else {
            setLoading(false);
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoading(false);
            toastError(fail.data?.err_msg);
          } else {
            setLoading(false);
            toastError();
          }
        }
      );
    } else {
    }
  };
  console.log("name", name);
  return (
    <section className="mct-section mct-section-contact-us">
      <Container>
        <div className="contact-us">
          <div className="mct-section-title">Contact Us</div>
          <Row>
            <Col>
              <span className="contact-us__subtitle">
                Let’s get in touch via message, email or call
              </span>
            </Col>
          </Row>
          <div className="contact-us__data">
            <form onSubmit={onFinish} className="contact-us__form wd-100">
              <div className="contact-us__form-field">
                <div className="contact-us__form-lbl">Name</div>
                <input
                  type={"text"}
                  name="name"
                  value={name}
                  onChange={handleOnChangeName}
                  className={`contact-us__form-input ${
                    nameError ? "bord-red" : ""
                  } `}
                />
                {nameError && (
                  <span className="contact-us__field-error">
                    Please, enter your name
                  </span>
                )}
              </div>
              <div className="contact-us__form-field">
                <div className="contact-us__form-lbl">Phone</div>
                <input
                  type={"text"}
                  name="phone"
                  value={phone}
                  onChange={handleOnChangePhone}
                  className={`contact-us__form-input ${
                    phoneError ? "bord-red" : ""
                  } `}
                />
                {phoneError && (
                  <span className="contact-us__field-error">
                    Please, enter your mobile number
                  </span>
                )}
              </div>
              <div className="contact-us__form-field">
                <div className="contact-us__form-lbl">Message</div>
                <textarea
                  type={"text"}
                  name="message"
                  value={message}
                  onChange={handleOnChangeMessage}
                  className={`contact-us__form-txtarea ${
                    messageError ? "bord-red" : ""
                  } `}
                />
                {messageError && (
                  <span className="contact-us__field-error">
                    Please, enter the message
                  </span>
                )}
              </div>
              <div className="contact-us__submit">
                <Button
                  loading={loading}
                  className="contact-us__form-btn"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              </div>
            </form>
            <div className="contact-us__social">
              <div className="contact-us__call-way">
                <div className="contact-us__img-figure">
                  <img
                    className="contact-us__image-fig"
                    src={phoneIcon}
                    alt="icon"
                  />
                </div>

                <span className="contact-us__phone-number">
                  +20100 16 865 61
                </span>
              </div>
              <div className="contact-us__call-way">
                <div className="contact-us__img-figure">
                  <img
                    className="contact-us__image-fig"
                    src={whatsAppIcon}
                    alt="icon"
                  />
                </div>

                <span className="contact-us__phone-number">
                  +20100 16 865 61
                </span>
              </div>
              <div className="contact-us__call-way">
                <div className="contact-us__img-figure">
                  <img
                    className="contact-us__image-fig"
                    src={mailIcon}
                    alt="icon"
                  />
                </div>

                <span className="contact-us__email">
                  maged.taha@mechanicalct.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ContactUs;
