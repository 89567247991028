import { Routes, Route } from "react-router-dom";
import SignIn from "./modules/Authentications/SignIn/SignIn";
import { ROUTES } from "./constants/_routes";
function RouterFile() {
	return (
		<Routes>
			<Route path={ROUTES.SIGNIN} element={<SignIn />} />
		</Routes>
	);
}
export default RouterFile;
