import React, { useState, useEffect } from "react";

function DetectWindowSize({ getBreakPointData }) {
  const [breakPoint, setBreakPoint] = useState(
    window.innerWidth >= 1400
      ? { winWidth: window.innerWidth, breakpoint: "xxl" }
      : window.innerWidth >= 1200 && window.innerWidth < 1400
      ? { winWidth: window.innerWidth, breakpoint: "xl" }
      : window.innerWidth >= 992 && window.innerWidth < 1200
      ? { winWidth: window.innerWidth, breakpoint: "lg" }
      : window.innerWidth >= 768 && window.innerWidth < 992
      ? { winWidth: window.innerWidth, breakpoint: "md" }
      : window.innerWidth >= 576 && window.innerWidth < 768
      ? { winWidth: window.innerWidth, breakpoint: "sm" }
      : { winWidth: window.innerWidth, breakpoint: "xs" }
  );
  const [dimentions, setDimentions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  useEffect(() => {
    const detectSize = () => {
      setDimentions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
      if (window.innerWidth >= 1400) {
        setBreakPoint({ winWidth: window.innerWidth, breakpoint: "xxl" });
      } else if (window.innerWidth >= 1200 && window.innerWidth < 1400) {
        setBreakPoint({ winWidth: window.innerWidth, breakpoint: "xl" });
      } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
        setBreakPoint({ winWidth: window.innerWidth, breakpoint: "lg" });
      } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        setBreakPoint({ winWidth: window.innerWidth, breakpoint: "md" });
      } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
        setBreakPoint({ winWidth: window.innerWidth, breakpoint: "sm" });
      } else {
        setBreakPoint({ winWidth: window.innerWidth, breakpoint: "xs" });
      }
    };
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [dimentions]);

  useEffect(() => {
    getBreakPointData(breakPoint);
    //eslint-disable-next-line
  }, [breakPoint]);

  return <></>;
}

export default DetectWindowSize;
