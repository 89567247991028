import axios from "axios";
import { responseErrorHandeler } from "@helpers/responseErrorHandeler";
export const baseUrl = process.env.REACT_APP_BASE_URL;
// export const baseUrl =
//   "";

let token = null;

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = false,
  multiPart = false
) {
  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      onFail(fail.response);
      responseErrorHandeler(fail?.response?.status);
    });
}

export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = false,
  multipart = false
) {
  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      onFail(fail.response);
      responseErrorHandeler(fail.response.status);
    }
  );
}
