import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import copyRightsIcon from "../../assets/icons/copyright.svg";
import logoIcon from "../../assets/icons/logoIcon.svg";
import locationIcon from "../../assets/icons/locationIcon.svg";

function Footer({ scrollToSection, active, getActiveLink }) {
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    if (active) {
      setActiveLink(active);
    }
  }, [active]);

  const handleScrollToSection = (section) => {
    scrollToSection(section);
    getActiveLink(section);
    setActiveLink(section);
  };

  return (
    <div className='mct-section-footer'>
      <Container className='footer-container'>
        <div className='footer '>
          <div className='footer__data'>
            {" "}
            <div className='footer__logo-address'>
              <div className='footer__logo'>
                <img
                  role='button'
                  className='footer__img'
                  src={logoIcon}
                  alt='logo'
                  onClick={() => handleScrollToSection("home")}
                />
              </div>
              <div className='footer__address-location'>
                <div className='footer__location'>
                  <img
                    className='footer__img'
                    src={locationIcon}
                    alt='location'
                  />
                </div>
                <div className='footer__address'>
                  {/* AlRehab city, inside Park 15 AlRehab <br /> club, gate16, new
                  cairo, Egypt */}
                  Knowhere Office Building No. G101, Block H6, Ibn Melka Street,
                  <br />
                  First Settlement, New Cairo, Cairo, Egypt
                </div>
              </div>
            </div>
            <div className='footer__links'>
              <ul>
                <li
                  role={"button"}
                  className={`footer__link mrg-y-10 ${
                    activeLink === "aboutUs" ? "active-link-foot" : ""
                  }`}
                  onClick={() => handleScrollToSection("aboutUs")}
                >
                  About Us
                </li>
                <li
                  role={"button"}
                  className={`footer__link mrg-y-10 ${
                    activeLink === "whatWeDo" ? "active-link-foot" : ""
                  }`}
                  onClick={() => handleScrollToSection("whatWeDo")}
                >
                  What we Do
                </li>
                <li
                  role={"button"}
                  className={`footer__link mrg-y-10 ${
                    activeLink === "ourPartners" ? "active-link-foot" : ""
                  }`}
                  onClick={() => handleScrollToSection("ourPartners")}
                >
                  Our Partners
                </li>
                <li
                  role={"button"}
                  className={`footer__link mrg-y-10 ${
                    activeLink === "founders" ? "active-link-foot" : ""
                  }`}
                  onClick={() => handleScrollToSection("founders")}
                >
                  Founders Reference List
                </li>
                <li
                  role={"button"}
                  className={`footer__link mrg-y-10 ${
                    activeLink === "contactUs" ? "active-link-foot" : ""
                  }`}
                  onClick={() => handleScrollToSection("contactUs")}
                >
                  Contact Us
                </li>
              </ul>
            </div>
          </div>

          <div className='footer__copy-rights'>
            <img src={copyRightsIcon} alt='copy-rights' className='mrg-xe-5' />{" "}
            <span calssName='footer__rights'>
              2023 MCT Mechanical C & T. All rights reserved
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
