import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import lightLogoIcon from "../../assets/icons/mctLightLogoIcon.svg";
import darkLogoIcon from "../../assets/icons/mctDarkLogoIcon.svg";
import DetectWindowSize from "../../helpers/DetectWindowSize";

function NavBar({ scrollToSection, getActiveLink, active }) {
  const [isScrolled, setIsScrolled] = useState(false);

  const [activeLink, setActiveLink] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [breakpointData, setBreakpointData] = useState(null);
  const collapseBtnRef = useRef(null);
  const collapseAreaRef = useRef(null);

  useEffect(() => {
    if (active) {
      setActiveLink(active);
    }
  }, [active]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 505) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToHome = (section) => {
    scrollToSection(section);
    setActiveLink(section);
    getActiveLink(section);
  };

  const handleToggleOnClick = (toggle) => {
    console.log("toggle", toggle);
    setIsCollapsed((prev) => !prev);
  };
  const handleCollapseOnClick = (collapse) => {
    // collapseBtnRef.current.className = "navbar-toggler collapsed";
    // collapseAreaRef.current.className = "navbar-collapse collapse";
    // setIsCollapsed((prev) => !prev);
  };

  return (
    <>
      <DetectWindowSize getBreakPointData={(data) => setBreakpointData(data)} />
      <Navbar
        className={`mct-navbar ${
          breakpointData?.winWidth <= 991 && !isCollapsed
            ? "white-navbar"
            : isScrolled
            ? "dark-navbar-scrolled"
            : "dark-navbar"
        }`}
        collapseOnSelect
        expand="lg"
        variant={!isCollapsed && breakpointData?.winWidth <= 991 ? "" : "dark"}
        fixed="top"
        // sticky="top"
      >
        <Container>
          <Navbar.Brand href="" onClick={() => handleScrollToHome("home")}>
            <div className="mct-navbar__logo-figure">
              <img
                className="mct-navbar__logoIcon"
                src={
                  !isCollapsed && breakpointData?.winWidth <= 991
                    ? darkLogoIcon
                    : lightLogoIcon
                }
                alt="logo"
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleToggleOnClick}
            ref={collapseBtnRef}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            onClick={handleCollapseOnClick}
            ref={collapseAreaRef}
          >
            <div className=" mct-navbar__menu-links ">
              <div className="mct-navbar__menu">
                {" "}
                <div
                  role="button"
                  className={`mct-navbar__page ${
                    activeLink === "aboutUs"
                      ? "active-link-nav"
                      : !isCollapsed && breakpointData?.winWidth <= 991
                      ? "page-light"
                      : "page-dark"
                  }`}
                  href="#aboutUs"
                  onClick={() => handleScrollToHome("aboutUs")}
                >
                  ABOUT US
                </div>
                <div
                  role="button"
                  className={`mct-navbar__page ${
                    activeLink === "whatWeDo"
                      ? "active-link-nav"
                      : !isCollapsed && breakpointData?.winWidth <= 991
                      ? "page-light"
                      : "page-dark"
                  }`}
                  href="#whatWeDo"
                  onClick={() => handleScrollToHome("whatWeDo")}
                >
                  WHAT WE DO
                </div>
                <div
                  role="button"
                  className={`mct-navbar__page ${
                    activeLink === "ourPartners"
                      ? "active-link-nav"
                      : !isCollapsed && breakpointData?.winWidth <= 991
                      ? "page-light"
                      : "page-dark"
                  }`}
                  href="#ourPartners"
                  onClick={() => handleScrollToHome("ourPartners")}
                >
                  OUR PARTNERS
                </div>
                <div
                  role="button"
                  className={`mct-navbar__page ${
                    activeLink === "founders"
                      ? "active-link-nav"
                      : !isCollapsed && breakpointData?.winWidth <= 991
                      ? "page-light"
                      : "page-dark"
                  }`}
                  href="#founders"
                  onClick={() => handleScrollToHome("founders")}
                >
                  FOUNDER'S REFERENCE LIST
                </div>
                <div
                  role="button"
                  className={`mct-navbar__page ${
                    activeLink === "contactUs"
                      ? "active-link-nav"
                      : !isCollapsed && breakpointData?.winWidth <= 991
                      ? "page-light"
                      : "page-dark"
                  }`}
                  href="#contactUs"
                  onClick={() => handleScrollToHome("contactUs")}
                >
                  CONTACT US
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* // ) : breakpointData?.winWidth <= 991 && breakpointData?.winWidth > 767 ? (
      //   <div className="nav-tab">
      //     <Container>
      //       <div className="nav-tab__content">
      //         <div className="nav-tab__logo-figure bord-red">
      //           <img className="nav-tab__logoIcon" src={logoIcon} alt="logo" />
      //         </div>
      //       </div>
      //     </Container>
      //   </div>
      // ) : (
      //   "mobile"
      // )} */}
    </>
  );
}

export default NavBar;
