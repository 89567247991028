import React, { useEffect, useState } from "react";
import founderPicThree from "../../assets/icons/founderPicThree.png";
import founderPicTwo from "../../assets/icons/foundersPicTwo.png";
import founderPicOne from "../../assets/icons/foundersPicOne.png";
import carouselDotIcon from "../../assets/icons/carouselDotIcon.svg";
import carouselActiveDotIcon from "../../assets/icons/carouselActiveDotIcon.svg";
import DetectWindowSize from "../../helpers/DetectWindowSize";

function Founders() {
  const [breakpointData, setBreakpointData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  let dataStyles = {
    fontSize: breakpointData?.winWidth <= 568 ? "16px" : "20px",
    fontWeight: "600",
    color: activeIndex === 0 ? "#ffffff" : "#000000",
    // marginBlockStart: "20px",
  };

  let allBackgroundsData = [
    {
      bg: founderPicOne,
      mirrorBg: "",
      title: "Founder’s Reference List",
      data: (
        <span style={dataStyles}>
          {``}
          <br />
          •&nbsp; ElSewedy PSP - Layyah CCPP - commissioning & startup Simple
          cycle phase. <br />
          •&nbsp; El Sewedy – Layyah CCPP – Steam blow design scope. <br />
          •&nbsp; El Sewedy PSP - Layyah CCPP Small Bore Piping design (1000MW).{" "}
          <br />
          •&nbsp; GE Grid – Toborouk 400KV S/S. <br />
          •&nbsp; GE Grid – Cairo west 500KV S/S temporary extension. <br />
          •&nbsp; Orascom – Pre bid support – Baiji 3 x SGT800 50MW simple cycle
          power plant. <br />
          •&nbsp; Korra Energi - EPC of Gas Compression Plant at Badr El Din 1
          Field. <br />
          •&nbsp; UNATRAC - DORC Genset PP – Lagos, Nigeria (1x14.5MW). <br />
          •&nbsp; Mantrac - CHP Biogas Genset PP (5x2MW). <br />
          •&nbsp; El Sewedy – PSP - Assiut CP-118 Small Bore Piping design
          (1x650MW). <br />
          •&nbsp; Sapesco - ANRPC steam blow. <br />
          •&nbsp; REPLACEMENT & REHABILITATION OF THE BOILER FEED PUMPS FOR ABU
          SULTAN POWER STATION UNITS 1,2, 3 and 4 (4x150MW). <br />
          •&nbsp; Unatrac - Sokoto Nigeria 30MW Diesel Plant. <br />
          •&nbsp; SDX & Upstream Advisors - Meseda Field (oil facility 20,000
          BPD). <br />
          •&nbsp; EMC - Petrosannan 2 Gas Compressors (2 x 5 MCFD). <br />
          •&nbsp; EMC - WEPCO 2 Gas Engines (2x2.5MW).
        </span>
      ),
      // dataColor: "#000000",
      dotIcon: carouselDotIcon,
    },
    {
      bg: founderPicTwo,
      mirrorBg: "#151d41",
      title: "Founder’s Reference List (CONT’D)",
      data: (
        <span style={dataStyles}>
          •&nbsp; Abu Qir REPLACEMENT & REHABILITATION OF THE BOILER FEED WATER
          PUMPS FOR Unit (2 & 7). <br />
          •&nbsp; Airliquide - Sidpec CO2 Plant. <br />
          •&nbsp; Airliquide - JUSHI Sokhna VSA (GOX plant). <br />• AL FARFRA
          DIESEL POWER STATION (MAN 4x4MW). <br />
          •&nbsp; SHOUBRAH EL-KHEIMA POWER STATION – REPLACEMENT &
          REHABILITATION OF THE FIRE FIGHTING NETWORK. <br />
          •&nbsp; American Embassy in iraq stand by generators (10x1mw). <br />
          •&nbsp; Jabal Omer development project – makkah mukarramah k.s.a (dcp
          10,000tr extension). <br />
          •&nbsp; Arab Contractors / tender of al-shabab and west damietta power
          project phase ii converting existing simple cycle to combined cycle
          (3x250mw). <br />
          •&nbsp; Arab Contractors / (tender of south helwan supercritical
          thermal power plant (3x650mw).
        </span>
      ),
      // dataColor: "#111111",
      dotIcon: carouselDotIcon,
    },
    {
      bg: founderPicThree,
      mirrorBg: "",
      title: "Founder’s Reference List (CONT’D)",
      data: (
        <span style={dataStyles}>
          •&nbsp; Azura, Nigeria, 450MW (4x125MW) Gas Turbine Simple Cycle Power
          Plant. <br />
          •&nbsp; Nouakchott, Mauritania, 120MW (8x15MW) Diesel Power Plant.{" "}
          <br />
          •&nbsp; Samawa, Iraq, 450MW (4x125MW) Gas Turbine Simple Cycle Power
          Plant. <br />
          •&nbsp; Qurayyah IPP, KSA, 4000MW Combined Cycle, Commissioning and
          operation services to Samsung C&T. <br />
          •&nbsp; Marsa Matrouh Desalination Plant 24000 m3/day,
          electromechanical erection services. <br />
          •&nbsp; Diwania, Iraq, 450MW (4x125MW) Gas Turbine Simple Cycle Power
          Plant. <br />
          •&nbsp; Cameroon 40MW Power Plant (three sites in three different
          cities) was successfully commissioned, started up and synchronized to
          the Cameroonian grid on Jan. 2012). <br />
          •&nbsp; Silpa 140 O2 and N2 industrial gases plant for egyptian
          market. <br />
          •&nbsp; Sigma O2, N2 and Air industrial gases plant for EZDK Company
          at Alexandria, Egypt. <br />
          •&nbsp; APSA N2 and Electrolyzer H2 industrial gases plant for Saint
          Gobain Glass Company at Soukhna, Egypt. <br />
          •&nbsp; Sigma O2, N2 and Air industrial gases plant for TCI Chemical
          Company at Port Said, Egypt. <br />
          •&nbsp; CP-118, PO‑109 & CP-107 packages for El-Tebbin 2x350MW Thermal
          Power Plant and part of Cairo North II 750MW Combined Cycle Power
          Plant. <br />
          •&nbsp; SULTAN POWER STATION UNITS 1,2, 3 and 4 (4x150MW).
        </span>
      ),
      // dataColor: "#111111",
      dotIcon: carouselDotIcon,
    },
  ];

  let sectionStyle = {
    backgroundImage: `url(${allBackgroundsData[activeIndex].bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    minHeight: "100vh",
  };

  let mirrorStyle = {
    width: "100%",
    minHeight: "100vh",
    borderRadius: "10px",
    background:
      activeIndex === 0
        ? "rgba(21, 29, 65, 0.75)"
        : "rgba(212, 194, 150, 0.51)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: activeIndex === 0 ? "blur(11.7px) " : "blur(11.3px)",
    WebkitBackdropFilter: activeIndex === 0 ? "blur(11.7px)" : "blur(11.3px)",
  };

  useEffect(() => {
    if (!isHovered) {
      const intervalId = setInterval(() => {
        setActiveIndex((activeIndex + 1) % allBackgroundsData?.length);
      }, 10000);

      return () => clearInterval(intervalId);
    }

    //eslint-disable-next-line
  }, [activeIndex, isHovered]);

  const handleSelectIndex = (index) => {
    setActiveIndex(index);
  };
  return (
    <div style={sectionStyle}>
      <DetectWindowSize getBreakPointData={(data) => setBreakpointData(data)} />
      <div className="founders ">
        <div
          role={"button"}
          className="founders__mirror"
          style={mirrorStyle}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onTouchStart={() => setIsHovered(true)}
          onTouchEnd={() => setIsHovered(false)}
        >
          <div
            className={`py-2 ${
              activeIndex === 0 ? "mct-section-title-w" : "mct-section-title-b"
            }`}
          >
            {allBackgroundsData[activeIndex].title}
          </div>
          {activeIndex === 0 && (
            <span className="founders__subtitle ">
              (BD to Closing Deals as well as Participation in successful
              execution)
            </span>
          )}
          <span className="">{allBackgroundsData[activeIndex].data}</span>
        </div>
        <div className="founders__dots">
          {allBackgroundsData?.map((dot, dotIndex) => (
            <div
              className="founders__dot-fig"
              onClick={() => handleSelectIndex(dotIndex)}
            >
              <img
                className="founders__dot "
                src={
                  activeIndex === dotIndex
                    ? carouselActiveDotIcon
                    : dot?.dotIcon
                }
                alt="dot"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Founders;
