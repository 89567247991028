import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import weAreIcon from "../../assets/icons/weAreIcon.png";
import weDeliverIcon from "../../assets/icons/weDeliverIcon.png";
import contdIcon from "../../assets/icons/contdIcon.png";

function WhatWeDo() {
  const [weAreIsTouched, setWeAreIsTouched] = useState(false);
  const [weAreIsHovered, setWeAreIsHovered] = useState(false);
  const [weDeliverIsHovered, setWeDeliverIsHovered] = useState(false);
  const [weDeliverIsTouched, setWeDeliverIsTouched] = useState(false);
  const [contdIsHovered, setContdIsHovered] = useState(false);
  const [contdIsTouched, setContdIsTouched] = useState(false);

  return (
    <section className='mct-section mct-section-whatwedo'>
      <Container>
        <div className='what-we-do'>
          <div className='mct-section-title'>What We Do</div>
          <Row justify='center'>
            <Col>
              <div className='blue-title'>Trading Services</div>
            </Col>
          </Row>
          <Row justify='center' className='mrg-ys-40 mrg-ye-20'>
            <Col>
              <div className='what-we-do__card-p'>
                Our Business Focusing on Power Generation, Oil & Gas,
                <br />
                Petrochemicals and Heavy Industries Sectors.
              </div>
            </Col>
          </Row>
          <div className='what-we-do__cards'>
            <div className='what-we-do__card '>
              <img
                className='what-we-do__card-img '
                src={weAreIcon}
                alt='weAreImg'
              />
              <div
                className='what-we-do__card-box '
                role='button'
                onMouseOver={() => setWeAreIsHovered(true)}
                onMouseLeave={() => setWeAreIsHovered(false)}
                onTouchStart={() => setWeAreIsTouched(true)}
                onTouchEnd={() => setWeAreIsTouched(false)}
              >
                {weAreIsHovered || weAreIsTouched ? (
                  <span className='what-we-do__card-box-p'>
                    • Representing Global Suppliers/ Manufacturers for
                    Mechanical /Process Equipment. <br /> • Representing
                    Technology Providers. <br /> • Representing Foreign EPC
                    contractors.
                  </span>
                ) : (
                  <span className='what-we-do__card-box-title'>We Are</span>
                )}
              </div>
            </div>
            <div className='what-we-do__card '>
              <img
                className='what-we-do__card-img'
                src={weDeliverIcon}
                alt='weDeliverImg'
              />
              <div
                className='what-we-do__card-box'
                role='button'
                onMouseOver={() => setWeDeliverIsHovered(true)}
                onMouseLeave={() => setWeDeliverIsHovered(false)}
                onTouchStart={() => setWeDeliverIsTouched(true)}
                onTouchEnd={() => setWeDeliverIsTouched(false)}
              >
                {weDeliverIsHovered || weDeliverIsTouched ? (
                  <span className='what-we-do__card-box-p'>
                    • Promoting our partners in Egypt while ensuring the highest
                    level of integrity. <br /> • Guiding our partners for market
                    opportunities and support closely in bidding stages. <br />•
                    Support in closing deals. <br /> • Close support during
                    project execution and after the handover to the end user.
                  </span>
                ) : (
                  <span className='what-we-do__card-box-title'>We Deliver</span>
                )}{" "}
              </div>
            </div>
          </div>
          <Row justify='center'>
            <Col>
              <div className='blue-title'>Construction Services</div>
            </Col>
          </Row>
          <Row justify='center' className='mrg-ys-40 mrg-ye-20'>
            <Col>
              <div className='what-we-do__card-p'>
                Tailored Mechanical and Process EPC “Engineering, Procurement &{" "}
                <br />
                Construction” scope, focusing on the following sectors.
              </div>
            </Col>
          </Row>
          <div className='what-we-do__cards'>
            {" "}
            <div className='what-we-do__card-const '>
              <img
                className='what-we-do__card-img'
                src={contdIcon}
                alt='countdImg'
              />
              <div
                className='what-we-do__card-box'
                role='button'
                onMouseOver={() => setContdIsHovered(true)}
                onMouseLeave={() => setContdIsHovered(false)}
                onTouchStart={() => setContdIsTouched(true)}
                onTouchEnd={() => setContdIsTouched(false)}
              >
                {contdIsHovered || contdIsTouched ? (
                  <span className='what-we-do__card-box-p'>
                    <strong>Construction Services</strong>
                    <br />• Tailored basic & detail engineering (multi
                    disciplinary) <br />
                    • Construction Supervision
                    <br />
                    • Tailored Mechanical and process EPC "Engineering, <br />
                    &nbsp; Procurement & Construction" scope.
                    <br />
                    <strong>
                      All above focusing on the following sectors:
                    </strong>
                    <br />
                    • Power Generation Sector <br /> • Oil & Gas and
                    Petrochemicals Sector <br /> • Heavy Industries Sector
                  </span>
                ) : (
                  <span className='what-we-do__card-box-title'>CONT’D</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default WhatWeDo;
