import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import partnerPicIcon from "../../assets/icons/partnersPicIcon.png";
import partnerPic2xIcon from "../../assets/icons/partnersPicIcon2x.png";
import partnerTrIcon from "../../assets/icons/partnerTrIcon.png";
import luvsideWebsiteLogo from "../../assets/icons/luvsideWebsiteLogo.svg";
import iciLogo from "../../assets/icons/ici.png";
import partnerTr2xIcon from "../../assets/icons/partnerTrIcon2x.png";
import DetectWindowSize from "../../helpers/DetectWindowSize";
import gccPowerGroupIcon from "../../assets/icons/gccGroupIcon.png";
import ceratoLogo from "../../assets/icons/ceratoLogo.png";

function OurPartners() {
  const [breakpointData, setBreakpointData] = useState(null);

  return (
    <section className=' mct-section-ourpartners'>
      <DetectWindowSize getBreakPointData={(data) => setBreakpointData(data)} />
      <Container>
        <div className='our-partners'>
          <Row>
            <Col>
              <div className='mct-section-title'>Our Partners</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='our-partners__subtitle'>
                WE REPRESENT & PROMOTE (CURRENT LIST & CONTINUING...)
              </div>
            </Col>
          </Row>
          <div className='our-partners__cards'>
            <div className='our-partners__card'>
              <div className='our-partners__card-img-pic'>
                <img
                  className='our-partners__img'
                  src={iciLogo}
                  alt='partner'
                />
              </div>
              <div className='our-partners__card-label'>
              ICI CALDAIE - The reputable Italian Fire Tube Boiler OEM No. 1 in ITALY              </div>
            </div>
            <div className='our-partners__card'>
              <div className='our-partners__card-img-pic'>
                <img
                  className='our-partners__img'
                  src={luvsideWebsiteLogo}
                  alt='partner'
                />
              </div>
              <div className='our-partners__card-label'>
                LUVSIDE - SMALL SCALE WIND TURBINES
              </div>
            </div>
            <div className='our-partners__card '>
              <div className='our-partners__card-img-pic'>
                <img
                  className='our-partners__img'
                  src={gccPowerGroupIcon}
                  alt='GCC Power Group'
                />
              </div>
              <div className='our-partners__card-label'>
                <strong>GCC</strong> Power Group
              </div>
              <p className='our-partners_card-para'>
                <strong>GCC</strong> Power Group Partners provide the following
                services:
              </p>
              <ul className='our-partners__details-list'>
                <li className='our-partners__details-list__item'>
                  Deep cleaning of boilers and HRSG's by using ultra high
                  pressure iceblasting&nbsp;
                  <a
                    target='_blank'
                    href='https://www.precision-iceblast.com'
                    rel='noreferrer'
                  >
                    <strong>www.precision-iceblast.com</strong>
                  </a>
                </li>
                <li className='our-partners__details-list__item'>
                  Boiler and HRSG related inspection services and
                  troubleshooting&nbsp;
                  <a
                    href='https://www.epcboilers.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {" "}
                    <strong>www.epcboilers.com</strong>
                  </a>
                </li>
                <li className='our-partners__details-list__item'>
                  Advanced Valve Solutions for high temperature and high
                  pressure steam applications provided by&nbsp;
                  <a
                    target='_blank'
                    href='https://www.avsnl.com'
                    rel='noreferrer'
                  >
                    {" "}
                    <strong>www.avsnl.com</strong>
                  </a>
                </li>
              </ul>
            </div>

            <div className='our-partners__card'>
              <div className='our-partners__card-img-pic our-partners__card-img-pic--big-img'>
                <img
                  className='our-partners__img'
                  src={ceratoLogo}
                  alt='cerato Logo'
                />
              </div>
              <div className='our-partners__card-label'>
                Certa Veritas Middle East
                <ul className='our-partners__details-list'>
                  <li className='our-partners__details-list__item'>
                    CerTa Veritas is an experienced, renowned, world-wide
                    operating specialist providing a one-stop solution for your
                    performance (testing and/or analysis) issues in power plants
                    and industrial energy systems.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default OurPartners;
