import { createSlice } from "@reduxjs/toolkit";

export const localSlice = createSlice({
	name: "local",
	initialState: {
		local: "en",
		dir: "ltr",
	},
	reducers: {
		changeLang: (state, action) => {
			state.local = state.local === "en" ? "ar" : "en";
			state.dir = state.dir === "ltr" ? "rtl" : "ltr";
		},
		changeLangOnPayload: (state, action) => {
			state.local = action.payload;
			state.dir = action.payload === "ar" ? "rtl" : "ltr";
		},
	},
});

const { reducer, actions } = localSlice;
export const { changeLang, changeLangOnPayload } = actions;
export default reducer;
