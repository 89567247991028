import React, { useEffect, useRef, useState } from "react";
import NavBar from "../Navbar/NavBar";
import LandingPage from "../LandingPage/LandingPage";
import AboutUs from "../AboutUs/AboutUs";
import OurPartners from "../OurPartners/OurPartners";
import Founders from "../Founders/Founders";
import Footer from "../Footer/Footer";
import WhatWeDo from "../WhatWeDo/WhatWeDo";
import ContactUs from "../ContactUs/ContactUs";
import DetectWindowSize from "../../helpers/DetectWindowSize";

function Global() {
  // const [isScrolled, setIsScrolled] = useState(false);
  // const [breakpointData, setBreakpointData] = useState(null);

  const [activeLink, setActiveLink] = useState(null);
  const landingPageRef = useRef(null);
  const aboutUsRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const ourPartnersRef = useRef(null);
  const foundersRef = useRef(null);
  const contactUsRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 200) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const scrollToSection = (section) => {
    switch (section) {
      case "home":
        landingPageRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "aboutUs":
        aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "whatWeDo":
        whatWeDoRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "ourPartners":
        ourPartnersRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "founders":
        foundersRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contactUs":
        contactUsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const landingPageObserver = new IntersectionObserver((entries) => {
      let entry = entries[0];
      if (entry?.isIntersecting) {
        setActiveLink("home");
      }
    });

    const aboutUsObserver = new IntersectionObserver((entries) => {
      let entry = entries[0];
      if (entry?.isIntersecting) {
        setActiveLink("aboutUs");
      }
    });

    const whatWeDoObserver = new IntersectionObserver((entries) => {
      let entry = entries[0];
      if (entry?.isIntersecting) {
        setActiveLink("whatWeDo");
      }
    });

    const ourPartnersObserver = new IntersectionObserver((entries) => {
      let entry = entries[0];
      if (entry?.isIntersecting) {
        setActiveLink("ourPartners");
      }
    });

    const foundersObserver = new IntersectionObserver((entries) => {
      let entry = entries[0];
      if (entry?.isIntersecting) {
        setActiveLink("founders");
      }
    });

    const contactUsObserver = new IntersectionObserver((entries) => {
      let entry = entries[0];
      if (entry?.isIntersecting) {
        setActiveLink("contactUs");
      }
    });

    landingPageObserver.observe(landingPageRef.current);
    aboutUsObserver.observe(aboutUsRef.current);
    whatWeDoObserver.observe(whatWeDoRef.current);
    ourPartnersObserver.observe(ourPartnersRef.current);
    foundersObserver.observe(foundersRef.current);
    contactUsObserver.observe(contactUsRef.current);
    return () => {
      landingPageObserver.unobserve(landingPageRef.current);
      aboutUsObserver.unobserve(aboutUsRef.current);
      whatWeDoObserver.unobserve(whatWeDoRef.current);
      ourPartnersObserver.unobserve(ourPartnersRef.current);
      foundersObserver.unobserve(foundersRef.current);
      contactUsObserver.unobserve(contactUsRef.current);
    };
  }, []);

  return (
    <div className="global-component">
      {/* <DetectWindowSize getBreakPointData={(data) => setBreakpointData(data)} /> */}
      <NavBar
        scrollToSection={scrollToSection}
        active={activeLink}
        getActiveLink={setActiveLink}
      />
      <div ref={landingPageRef}>
        <LandingPage />
      </div>
      <div ref={aboutUsRef}>
        <AboutUs />
      </div>
      <div ref={whatWeDoRef}>
        <WhatWeDo />
      </div>
      <div ref={ourPartnersRef}>
        <OurPartners />
      </div>
      <div ref={foundersRef}>
        <Founders />
      </div>
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
      <Footer
        scrollToSection={scrollToSection}
        active={activeLink}
        getActiveLink={setActiveLink}
      />
    </div>
  );
}

export default Global;
